import Vue from 'vue';
import { Link } from '../../types/link';
import { importDebounce, importScrollLock } from '../../assets/js/utilities/dynamicImports';
import { HeaderMobileProps } from './HeaderMobile.props';

// Component ---------------------------------------------------------------------------------------

export default Vue.extend({
	name: 'HeaderMobile',
	props: HeaderMobileProps,
	data() {
		return {
			showMenu: false,
			showSearch: false,
			searchTouched: false,
			resizeObserver: {} as ResizeObserver,
			debouncedOnSize: null as EventListener | null,
		};
	},
	mounted() {
		this.resizeObserver = new ResizeObserver(() => this.debouncedOnSize);
		this.resizeObserver.observe(this.$el as HTMLElement);
		this.$root.$on('header-click-link', this.handleMenuClose);
		this.saveInnerHeightToCssVar();

		importDebounce().then(({ debounce }) => {
			this.debouncedOnSize = debounce(this.saveInnerHeightToCssVar, 250) as EventListener;
			addEventListener('resize', this.debouncedOnSize); // Fix for iPhone 13
		});
	},
	beforeDestroy() {
		this.resizeObserver.disconnect();
		removeEventListener('resize', this.debouncedOnSize as EventListener);
	},
	methods: {
		handleLanguageChange(language: Link) {
			this.$emit('language-change', language);
		},
		handleMenuOpen() {
			this.showMenu = true;
		},
		handleMenuClose() {
			this.showMenu = false;
		},
		handleSearchShow() {
			this.searchTouched = true;
			this.showSearch = !this.showSearch;

			this.$root.$emit('header-mobile-search-show');
		},
		handleSearchTrigger() {
			this.showSearch = false;
		},
		handleOverlayClick() {
			this.showMenu = false;
			this.showSearch = false;

			importScrollLock().then(({ unlock }) => unlock());
		},
		saveInnerHeightToCssVar() {
			const vh = window.innerHeight * 0.01;

			(this.$el as HTMLElement).style.setProperty('--vh', `${vh}px`);
		},
	},
});
