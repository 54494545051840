import { PropType } from 'vue';
import { Props } from '../../types/core';
import { BurgerListModel } from '../BurgerList/BurgerList.props';
import { ClubCtaModel } from '../ClubCta/ClubCta.props';
import { LanguageSwitcherModel } from '../LanguageSwitcher/LanguageSwitcher.props';
import { LinkListModel } from '../LinkList/LinkList.props';
import { LogoModel } from '../Logo/Logo.props';
import { SearchModel } from '../Search/Search.props';
import { StoresCtaModel } from '../StoresCta/StoresCta.props';
import { LoginCtaModel } from '../LoginCta/LoginCta.props';
import { MiniCartModel } from '../MiniCart/MiniCart.props';
import { SearchTriggerModel } from '../SearchTrigger/SearchTrigger.props';

// Model -------------------------------------------------------------------------------------------

export interface HeaderMobileModel {
	uid: string;
	logo: LogoModel;
	storesCta: StoresCtaModel;
	clubCta: ClubCtaModel;
	searchTrigger: SearchTriggerModel;
	languageSwitcher: LanguageSwitcherModel;
	genderNavigation: LinkListModel;
	burgerList: BurgerListModel;
	search: SearchModel;
	loginCta: LoginCtaModel;
	cart: MiniCartModel;
	showSearchTrigger?: boolean;
}

// Props -------------------------------------------------------------------------------------------

export const HeaderMobileProps: Props<HeaderMobileModel> = {
	uid: {
		type: String as PropType<HeaderMobileModel['uid']>,
		required: true,
	},
	logo: {
		type: Object as PropType<HeaderMobileModel['logo']>,
		required: true,
	},
	storesCta: {
		type: Object as PropType<HeaderMobileModel['storesCta']>,
		required: true,
	},
	clubCta: {
		type: Object as PropType<HeaderMobileModel['clubCta']>,
		required: true,
	},
	searchTrigger: {
		type: Object as PropType<HeaderMobileModel['searchTrigger']>,
		required: true,
	},
	languageSwitcher: {
		type: Object as PropType<HeaderMobileModel['languageSwitcher']>,
		required: true,
	},
	genderNavigation: {
		type: Object as PropType<HeaderMobileModel['genderNavigation']>,
		required: true,
	},
	burgerList: {
		type: Object as PropType<HeaderMobileModel['burgerList']>,
	},
	loginCta: {
		type: Object as PropType<HeaderMobileModel['loginCta']>,
		required: true,
	},
	cart: {
		type: Object as PropType<HeaderMobileModel['cart']>,
		required: true,
	},
	search: {
		type: Object as PropType<HeaderMobileModel['search']>,
		required: true,
	},
	showSearchTrigger: {
		type: Boolean as PropType<HeaderMobileModel['showSearchTrigger']>,
		default: false,
	},
};
